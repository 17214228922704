import { computed, ref } from 'vue';
import {
  createEnrollments,
  fetchEnrollments,
  unlockStage,
} from '/@/app/services/projects';
import { useStudentsStore } from '/@/app/store/students';
import { IProjectEnrollment } from '/@/app/models/interfaces';

interface UseProjectProps {
  group: string;
  course: string;
}

const projectEnrollments = ref<IProjectEnrollment[]>([]);

export function useProject({ group, course }: UseProjectProps, project) {
  const studentsStore = useStudentsStore();

  const setEnrollment = (onSuccess?: () => void) => {
    if (!group || !project.value) {
      resetEnrollment();
      return;
    }
    const { onSuccess: onSuccessEnrollments } = fetchEnrollments(
      project.value,
      group,
    );
    onSuccessEnrollments(({ data }) => {
      if (data.length) {
        projectEnrollments.value = data;
      }
      onSuccess?.();
    });
  };

  const resetEnrollment = () => {
    projectEnrollments.value = [];
  };

  const projectStages = computed(() => {
    return projectEnrollments.value?.[0]?.summary?.map(item => ({
      ...item,
      id: item.stage.id,
    }));
  });

  const distribute = ({
    introductionText,
    onSuccess,
    onError,
  }: {
    introductionText: string;
    onSuccess: () => void;
    onError: () => void;
  }) => {
    if (!group || !project.value) {
      return;
    }

    const { onSuccess: onSuccessEnrollments, onError: onErrorEnrollments } =
      createEnrollments(project.value, group, course, introductionText);

    onSuccessEnrollments(() => {
      setEnrollment();
      studentsStore.setCourseModule(group, course);
      onSuccess();
    });
    onErrorEnrollments(() => {
      onError();
    });
  };

  const unLockProjectStage = (
    stageId: string,
    {
      onSuccessSubmit,
      onErrorSubmit,
    }: { onSuccessSubmit: () => void; onErrorSubmit: () => void },
  ) => {
    const { onSuccess, onError } = unlockStage(project.value, stageId, group);
    onSuccess(() => {
      setEnrollment();
      studentsStore.setCourseModule(group, course);
      onSuccessSubmit();
    });
    onError(() => {
      onErrorSubmit();
    });
  };

  const assessments = computed(() => {
    if (!projectStages.value?.length) {
      return {};
    }

    return {
      title: 'תירגול מעשי',
      items: [
        {
          id: project.value,
          title: '1. פרויקט מעשי',
          hideArrow: true,
          items: projectStages.value.map(
            ({ stage, locked, status }, index) => ({
              id: stage.id,
              title: stage.title,
              data: {
                lesson: {
                  id: stage.id,
                  title: stage.title,
                  isProject: true,
                },
                locked: locked,
                lessonIndex: index + 1,
                status,
              },
            }),
          ),
        },
      ],
    };
  });

  return {
    projectStages,
    projectEnrollments,
    assessments,
    setEnrollment,
    distribute,
    unLockProjectStage,
    resetEnrollment,
  };
}
