import { computed } from 'vue';
import { useStudentsStore } from '/@/app/store/students';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useProject } from './useProject';
import { unlockLesson } from '/@/app/services/students';
import { useCourseStore } from '/@/app/store/course';

const tabs = [
  { name: 'lessons', title: 'שיעורים' },
  { name: 'grades', title: 'ציונים' },
];

const truncateString = (str, length) => {
  return str.length > length ? str.slice(0, length) + '...' : str;
};

export function useCourse(groupId: string, courseId: string) {
  const router = useRouter();
  const studentsStore = useStudentsStore();
  const courseStore = useCourseStore();

  const { t } = useI18n();

  const courseModule = computed(() =>
    studentsStore.getCourseModule(groupId, courseId),
  );

  const courseData = computed(() => courseStore.course);
  const courseFiles = computed(() => courseStore.files);

  const projectId = computed(
    () =>
      courseData.value?.assessments?.find(({ type }) => type === 'project')
        ?.reference || '',
  );

  const projectDistributed = computed(() =>
    courseModule.value?.assessments?.find(
      assessment => assessment.reference === projectId.value,
    ),
  );

  const teamsEnabled = computed(() =>
    courseModule.value?.summary?.some(lesson => lesson.teams_enabled),
  );

  const lessonsOptions = computed(() => {
    const lessons =
      courseModule.value?.summary
        ?.sort((a, b) => a.lesson.lesson - b.lesson.lesson)
        ?.map(l => ({
          id: l.lesson.id,
          title: truncateString(l.lesson.title, 40),
        })) || [];

    if (projectId.value) {
      lessons.push({
        id: projectId.value,
        title: 'פרויקט מעשי',
      });
    }

    return lessons;
  });

  const selectedLessonById = (id: string) => {
    return lessonsOptions.value.find(lesson => lesson.id === id) || {};
  };

  const resetCourseModule = () => {
    studentsStore.clearCourseModule();
  };

  const {
    assessments,
    projectStages,
    projectEnrollments,
    setEnrollment,
    unLockProjectStage,
  } = useProject({ group: groupId, course: courseId }, projectId);

  const loadCourseData = () => {
    studentsStore.setCourseModule(groupId, courseId);
    studentsStore.fetchEnrollments({ group: groupId, course: courseId });

    courseStore.fetchCourse(courseId);
    courseStore.fetchCourseFiles(courseId);
  };

  const unitsLessons = computed(() => {
    const units = courseModule.value?.course?.units ?? [];
    const data = units.map(({ id, title, level }) => {
      const lessons = courseModule.value.summary
        .sort((a, b) => a.lesson.lesson - b.lesson.lesson)
        .map((lesson, index) => ({
          ...lesson,
          lessonIndex: index + 1,
        }))
        .filter(({ lesson }) => lesson.unit === id);

      return {
        title: title || t(`level.${level}`),
        items: lessons.map(lesson => ({
          title: lesson.lesson.title,
          id: lesson.lesson.id,
          data: lesson,
        })),
      };
    });
    return [
      ...data,
      ...(Object.keys(assessments.value).length ? [assessments.value] : []),
    ];
  });

  const navigateToPreview = lesson => {
    const { href } = router.resolve({
      name: 'preview',
      params: {
        course: courseId,
        lesson,
      },
      query: { course_module_group: groupId },
    });
    window.open(href, '_blank');
  };

  const navigateToPreviewProject = (stage: string) => {
    const { href } = router.resolve({
      name: 'project-preview',
      params: {
        project: projectId.value,
        stage,
      },
      query: { course_module_group: groupId },
    });
    window.open(href, '_blank');
  };

  const unLockLesson = ({
    lessonId,
    date,
    onSuccessSubmit,
    onErrorSubmit,
  }: {
    lessonId: string;
    date?: Date;
    onSuccessSubmit: () => void;
    onErrorSubmit: () => void;
  }) => {
    const { onSuccess, onError } = unlockLesson(
      groupId,
      courseId,
      lessonId,
      date,
    );
    onSuccess(() => {
      studentsStore.setCourseModule(groupId, courseId);
      onSuccessSubmit();
    });
    onError(() => {
      onErrorSubmit();
    });
  };

  const unLock = (
    {
      date,
      onSuccessSubmit,
      onErrorSubmit,
    }: { date?: Date; onSuccessSubmit: () => void; onErrorSubmit: () => void },
    selectedLesson: any,
  ) => {
    if (selectedLesson?.isProject) {
      unLockProjectStage(selectedLesson.id, { onSuccessSubmit, onErrorSubmit });
    } else {
      unLockLesson({
        lessonId: selectedLesson.id,
        date,
        onSuccessSubmit,
        onErrorSubmit,
      });
    }
  };

  return {
    tabs,
    courseModule,
    courseData,
    courseFiles,
    unitsLessons,
    teamsEnabled,
    projectId,
    projectEnrollments,
    projectStages,
    assessments,
    lessonsOptions,
    projectDistributed,
    setEnrollment,
    navigateToPreview,
    navigateToPreviewProject,
    loadCourseData,
    unLock,
    selectedLessonById,
    resetCourseModule,
  };
}
